/* Media Queries */
@media screen and (max-width: 786px) {
  .Footer {
    justify-content: center;
    height: 200px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    background-color: #1c1c1c;
    width: 100vw;
  }
  .FooterText {
    margin-left: 0;
  }
  .FooterNav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4em;
    width: 90%;
  }
  
  a{
    color: white;
    text-decoration: none;
  }
  
  a:hover{
    color: #fbc51b;
  }
}

@media (min-width: 786px) {
  .Footer {
    width: 100%;
    height: 77px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1c1c1c;
    margin-top: 80px;
  }
  .FooterText {
    color: #777777;
    margin-left: 28px;
  }
  
  .FooterNav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 28px;
    width: 20%;
  }
  
  a{
    color: white;
    text-decoration: none;
  }
  
  a:hover{
    color: #fbc51b;
  }
}
  