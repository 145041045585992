.Nav {
    width: 92%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    z-index: 1000;
}
.TellorLogo {
    width: 140px;
}

/* Media Queries */
@media screen and (max-width: 786px) {
    .Nav {
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        height: 60px;
        background-color: #1c1c1c;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
    }
    .TellorLogo {
        font-size: 40px;
        width: 50px;
    }
}
@media (max-width: 500px) {
    .Nav {
        justify-content: space-around;
    }
}